import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { Page, View, Text, WhiteGreyLayout, SEO, Table } from 'lib'
import theme from '_appSetup/Theme'
import { Tabs, Tab } from '@material-ui/core'
import moment from 'moment'

import { useUserData, formatUSD } from '../dashboard/_utils'

import '_appSetup/Global.css'
import WithdrawalFormLuna from './_withdrawalFormLuna'

const WithdrawPage = (props) => {
  const styles = useStyles(props)

  const LeftComponent = () => {
    return (
      <View style={styles.leftWrapper}>

        <View style={styles.depositInfoWrapper}>

          <Text variant='h5' gutterBottom style={styles.header}>Claim LUNA airdrop</Text>

          <WithdrawalFormLuna/>

        </View>
      </View>
    )
  }

  const RightComponent = () => {
    return null
  }

  return (
    <Page>
      <SEO title='Withdraw'/>
      <WhiteGreyLayout
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />
    </Page>
  )
}


const useStyles = makeStyles({
  topWrapper: {
    marginTop: theme.spacing(4),
  },
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  depositInfoWrapper: {
    paddingBottom: theme.spacing(4),
    alignSelf: 'stretch',
    wordBreak: 'break-word',
  },
  head: {
    marginBottom: theme.spacing(1),
  },
  spaced: {
    marginTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  textHeader: {
    fontSize: 12,
    fontWeight: 600,
    // marginBottom: theme.spacing(2),
  },
  textHeaderSpaced: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(4),
  },
})

export default WithdrawPage
